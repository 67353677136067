.about {
  background: transparent;
  margin-top: 50px;
  margin-left: 50px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  align-items: center;
}

.about-text {
  display: grid;
  margin-left: 20%;
  margin-right: -100px;
}

.text {
  color: #fff;
  font-family: "Neue Machina";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  width: 90%;
  overflow-wrap: break-word;
}

.about-head {
  font-family: "Neue Machina";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(
    90deg,
    #a14604 0%,
    #ff6c01 56.3%,
    #a14604 104.95%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
}

.about_img {
  width: 80%;
  z-index: 4;
  margin-left: 0;
  margin: auto;
}

@media (max-width: 801px) {
  .about {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    margin-top: -280px;
    padding: 0 30px;
  }

  .about_img {
    margin: auto;
    order: -1;
    width: 100%;
    height: auto;
  }

  .about-text,
  .about-head,
  .text {
    grid-column: 1 / -1;
  }
}

@media (min-width: 2300px) {
  .about .about-head {
    font-size: 75px;
  }
  .about .about-text p {
    font-size: 36px;
  }
}

@media (max-width: 1681px) {
  .about .about-head {
    font-size: 56px;
  }

  .about .about-text p {
    font-size: 24px;
  }
}

@media (max-width: 1445px) {
  .about .about-head {
    font-size: 51px;
  }

  .about .about-text p {
    font-size: 23px;
  }
}

@media (max-width: 1030px) {
  .about .about-head {
    font-size: 38px;
  }

  .about .about-text p {
    font-size: 16px;
  }
}

@media (max-width: 801px) {
  .about {
    margin: 0;
    padding: 0;
    padding-bottom: 50px;
  }
  .about .about-head {
    margin: auto;
    margin-bottom: 5px;
    align-self: center;
    text-align: center;
    font-size: 38px;
  }
  .about .about-text {
    justify-content: center;
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .about .about-text p {
    font-size: 17px;
    margin: auto;
    line-height: 21px;
    text-align: justify;
  }
  .about .about_img {
    width: 70%;
  }
}

@media (max-width: 500px) {
  .about .about-text p {
    font-size: 16.6px;
    line-height: 21px;
  }
  .about .about-head {
    font-size: 31px;
  }
}
