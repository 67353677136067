.scroll-button {
  cursor: pointer;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  min-width: 93px;
  min-height: 33px;
  border-radius: 40px;
  margin-bottom: 15px;
  margin-top: 15px;
  transition: 0.5s ease;

}


.scroll-button:hover {

}

.button_container {
  display: flex;
  justify-content: center;
  gap: 20%;
}

.scroll-button:hover {
  background-color: transparent;
  opacity: 0.6;
}

