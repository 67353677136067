.marquee,
.marquee-content,
.marquee-content-right {
  will-change: transform;
}

.clients_text {
  display: flex;
  margin: auto;
  color: #fff;
  font-family: "Neue Machina";
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  margin-bottom: 35px;
  justify-content: center;
  text-wrap: nowrap;
}

.marquee-content {
  display: flex;
}

.logo {
  width: auto;
  height: 2vw;
  margin-right: 3vw;

}

.logo2 {
  margin-top: 30px;
  width: auto;
  height: 2.4vw;
  margin-right: 3vw;
}

@media (max-width: 1024px) {
  .logo {
    width: auto;
    height: 3vw;
    margin-right: 3vw;

  }

  .logo2 {
    margin-top: 30px;
    width: auto;
    height: 3.8vw;
    margin-right: 3vw;
  }

  .clients_text {
    font-size: 3.5vw;
  }
}

@media (max-width: 541px) {
  .logo {
    width: auto;
    height: 5vw;
    margin-right: 4vw;

  }

  .logo2 {
    margin-top: 30px;
    width: auto;
    height: 6.3vw;
    margin-right: 4vw;
  }

  .clients_text {
    font-size: 6vw;
  }
}
@media (max-width: 391px) {
  .logo {
    width: auto;
    height: 5.8vw;
    margin-right: 8vw;

  }

  .logo2 {
    margin-top: 30px;
    width: auto;
    height: 7.3vw;
    margin-right: 8vw;
  }

  .clients_text {
    font-size: 6vw;
  }
}