.revCard {
  /* min-width: 640px;
  max-width: 640px; */

  height: 260px;
  border-radius: 40px;
  border: 1px solid #fff;
  background: #0a090d;
  padding: 30px;
  flex-wrap: nowrap;

  position: relative;
  transition: left 10s ease;
}

.reviews-slider {
  width: 100%;
}

.revCard img {
  width: 82px;
  height: 82px;
  margin-right: 30px;
  border-radius: 40px;
}

.revCard .upside {
  display: flex;
  margin: 0;
  height: min-content;
  margin-bottom: 5px;
}

.revCard .svg {
  margin-left: auto;
}

.revCard h3 {
  color: #ff6c01;
  font-family: "Neue Machina";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0.4px;
}

.revCard h1 {
  color: #fff;
  font-family: "Neue Machina";
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
}

.revCard p {
  display: flex;
  color: #fff;
  font-family: "Neue Machina";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.4px;
  width: 600px;
}

@media (max-width: 712px) {
  .revCard {
    height: auto;
    min-height: 350px;
  }

  .revCard p {
    max-width: 330px;
  }
}

@media (max-width: 522px) {
  .revCard {
    height: 160px !important;
    min-height: 0px;
    padding: 30px 14px;
    border-radius: 28px;
  }

  .revCard .upside {
    max-width: 420px;
  }

  .revCard h1 {
    font-size: 24px;
  }

  .revCard h3 {
    font-size: 12px;
  }

  .revCard p {
    font-size: 14px;
    line-height: 18px;
  }

  .revCard img {
    width: 46px;
    height: 46px;
  }
}

@media (max-width: 452px) {
  .revCard img {
    margin-right: 12px;
  }

  .revCard p {
    font-size: 11px;
    line-height: 19px;
    max-width: 78vw;
  }

  .revCard .upside {
    max-width: 80vw;
  }

  .revCard h1 {
    font-size: 20px;
  }

  .revCard h3 {
    font-size: 8px;
    line-height: 13px;
  }

  .svg {
    display: none;
  }
}

@media (max-width: 420px) {
  .revCard {
    height: 180px !important;
  }
}

@media (max-width: 300px) {
  .revCard {
    height: 200px !important;
  }
}
