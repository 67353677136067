.contact-modal {
  z-index: 99999;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}
.captcha-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.captcha-modal button {
  width: 302px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 2px solid #ff6b00;
  background: linear-gradient(90deg, #a14604 0%, #ff6c01 53.65%, #a14604 100%),
    linear-gradient(90deg, #52188b 3%, #9f3dff 44.83%, #52188b 97.66%);
  color: #fff;
  text-align: center;
  font-family: "Neue Machina";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.captcha-content {
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rodal-dialog {
  width: 450px !important;
  height: 450px !important;
  border-radius: 30px;
  padding: 30px 20px;
}

.contact-modal-container {
}

.modal-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.modal-label {
  font-size: 18px;
  font-weight: 700;
  line-height: 17.37px;
  margin-bottom: 4px;
  font-family: "Neue Machina";
  margin-left: 26px;
}

.contact-modal-input {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  border: 0.75px solid black;
  padding: 30px;
  font-size: 18px;
  font-family: "Neue Machina";
  color: black;
}

.contact-modal-input::placeholder {
  color: #e4e4eb;
}

.contact-modal-submit-btn {
  width: 100%;
  height: 62px;
  border-radius: 30px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-family: "Neue Machina";
  border: 0.75px solid black;
  margin-top: 32px;
  font-weight: 700;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}

.contact-modal-submit-btn-hover-effect:hover {
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background-color: transparent;
  color: black;
}

.contact-modal-close-btn {
  border: 0;
  background: none;
  font-size: 20px;
  position: absolute;
  right: 20px;
  top: 4px;
}

.contact-modal-close-btn:hover {
  opacity: 0.5;
}

.contact-modal-submit-btn-disabled {
  opacity: 0.5;
}

@media (max-width: 470px) {
  .rodal-dialog {
    width: 380px !important;
  }
}

@media (max-width: 400px) {
  .rodal-dialog {
    width: 300px !important;
  }
}

@media (max-width: 310px) {
  .rodal-dialog {
    width: 250px !important;
    border-radius: 27px;
    padding: 30px 20px;
    height: 400px !important;
  }

  .contact-modal-input {
    height: 20px;
    padding: 26px;
  }

  .contact-modal-submit-btn {
    height: 52px;
  }

  .modal-label {
    font-size: 14px;
  }
}
